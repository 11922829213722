<script setup lang="ts">
import { toHead } from 'vue-datocms';
import { ContentModules } from '~/components/content-modules/ContentModules';
import { HeaderModules } from '~/components/header-modules/HeaderModules';
import { useContainerIntersections } from '~/utils/useContainerIntersections';

const route = useRoute();
const slug = String(route.params.slug);
const { locale, localeProperties } = useI18n();

const { data, error } = await useAsyncGql({
  operation: 'GetPageByTranslatedSlug',
  variables: {
    slug: slug,
    locale: localeProperties.value.siteLocale
  }
});

const page = data.value.page;

if (!page || error.value) {
  showError({
    statusCode: 404
  });
}

useHead(toHead(page?.seo ?? {}));

if (page) {
  const pathIt = page._allTranslatedSlugLocales?.find((i) => i.locale === 'it_CH')?.value || slug;
  const pathDe = page._allTranslatedSlugLocales?.find((i) => i.locale === 'de_CH')?.value || slug;
  const pathFr = page._allTranslatedSlugLocales?.find((i) => i.locale === 'fr_CH')?.value || slug;

  const setI18nParams = useSetI18nParams({
    // canonicalQueries: [slug]
  });

  setI18nParams({
    fr: { slug: pathFr },
    it: { slug: pathIt },
    de: { slug: pathDe }
  });
}

const containerElements = ref<HTMLElement[]>([]);

useContainerIntersections(containerElements);
</script>

<template>
  <main v-if="page" class="layout-default" :class="`-page-${slug}`">
    <!--    <pre>{{ page }}</pre>-->
    <div class="container" v-for="(content, index) in page.contentCards" ref="containerElements">
      <div class="wrapper" :class="`-background-${content.background}`">
        <component
          v-if="index === 0 && page.headerModule"
          :is="HeaderModules[page.headerModule.__typename]"
          :data="page.headerModule"
        >
        </component>
        <component
          v-for="module in content.cards"
          :key="module.id"
          :is="ContentModules[module.__typename]"
          :data="module"
        >
        </component>
      </div>
    </div>
  </main>
</template>

<style scoped lang="scss">
//.layout-default {
//  // ..
//}
</style>
